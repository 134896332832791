import React from "react"
import { graphql } from "gatsby"
import LayoutHeadFoot from "../components/Shared/Layouts/LayoutHeadFoot";
import ContactUs from "../components/ContactUsPage/ContactUs";
import SEO from "../components/seo";


const Contact = ({ data }) => {

  const contactData = data?.allStrapiContactUs?.edges[0].node;

  return (
    <>
      <SEO seo={contactData.metaData} />
      <LayoutHeadFoot>
        <ContactUs data={contactData} />
      </LayoutHeadFoot>
    </>
  )
}

export const query = graphql`
query ContactQuery {
  allStrapiContactUs {
    edges {
      node {
        contactText
        contactFormTitle     
        metaData {
          metaTitle
          metaDescription
          shareImage {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
}`

export default Contact


