import React from "react"
import ContactUsForm from "./ContactUsForm"

const ContactUs = ({ data }) => {

    return (
        <div className="gradientBlue">
            <div className="py-28" />
            <div className="lg:container px-4 lg:px-0 mb-24 py-12">
                <div className="contact-box-border w-12/12 md:w-11/12 xl:w-10/12 mx-auto grid grid-cols-1 md:grid-cols-2 md:gap-x-8 gap-x-12 p-4 md:p-6 lg:p-10 bg-white">
                    <div className="text-editor contact-p-margins" dangerouslySetInnerHTML={{ __html: data.contactText }} />
                    <div className="mt-8 md:mt-0">
                        <div className="text-editor" dangerouslySetInnerHTML={{ __html: data.contactFormTitle }} />
                        <ContactUsForm location="Contact Us Page" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactUs
